<template>
  <div>
    <Nav :pt="isIndex" />
    <div class="regSuccess-wrap">
        <div class="reg-text-wrap">
            <img src="@/assets/RegSuccess.png" alt="" class="reg-img">
            <span class="reg-text">报名成功</span>
        </div>
        <van-button round type="info" class="bottom-button" @click="routerTo">返回首页</van-button>
    </div>
    <Footer />
  </div>
</template>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import { Button } from "vant";
export default {
  data() {
    return {
      isIndex: false
    };
  },
  components: {
    "van-button": Button,
    Nav,
    Footer
  },
  methods: {
    routerTo() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style scoped>
.regSuccess-wrap {
  margin: 200px 0 200px 0;
  text-align: center;
}
.reg-text-wrap {
  font-size: 20px;
  color: #07c160;
  margin-bottom: 50px;
}
.reg-img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.reg-img,
.reg-text {
  vertical-align: middle;
}
</style>